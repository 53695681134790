import React from 'react';

import { Alert, AlertIcon, AlertTitle, AlertDescription, Link } from '@chakra-ui/react';
import { useGetUserSelfQuery } from 'services/api/user';
import Button from 'components/common/Button';
import { getDateTime } from 'utils/dateTime';

import { STRIPE_BUTTON_LINK } from 'utils/consts';
import { getStripeCheckoutSession } from 'utils/sessionStorage';

const SubcribeAlert = () => {
  const userSelfQuery = useGetUserSelfQuery();

  // If user is on free plan
  if (userSelfQuery?.isSuccess && userSelfQuery.data.plan_tier === 'free') {
    if (getStripeCheckoutSession()) {
      return (
        <Alert status="info" borderRadius="lg" marginBottom={4}>
          <AlertIcon />
          <AlertTitle>Your subscription will be updated in a short time!</AlertTitle>
          <AlertDescription>Thank you for your payment.</AlertDescription>
        </Alert>
      );
    } else if (STRIPE_BUTTON_LINK) {
      const { plan_expiry } = userSelfQuery.data;
      return (
        <Alert status="warning" borderRadius="lg" marginBottom={4}>
          <AlertIcon />
          <AlertTitle>Your subscription is about to expire at {getDateTime(plan_expiry.toString(), '-')}!</AlertTitle>
          <AlertDescription>Please upgrade your plan to continue using the app.</AlertDescription>
          <Link href={STRIPE_BUTTON_LINK}>
            <Button colorScheme="blue" size="sm" ml={2}>
              Upgrade Plan
            </Button>
          </Link>
        </Alert>
      );
    }
  }

  return null;
};

export default SubcribeAlert;
